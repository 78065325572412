<template>
  <div>
    <table
      v-if="flowArea.releaseArea && flowArea.releaseArea.length"
      :class="['table kendo-table table-kendo', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].ReleaseArea') }]"
    >
      <colgroup>
        <col width="35">
      </colgroup>
      <thead>
        <tr>
          <th />
          <th>{{ $t('pan') }}</th>
          <th>{{ $t('tilt') }}</th>
          <th>{{ $t('option') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in flowArea.releaseArea"
          :key="`tableRow-${ index }`"
        >
          <td>{{ index }}</td>
          <td>
            <input
              v-model.number="item.pan"
              type="number"
              class="form-control form-control-sm"
              min="-170"
              max="170"
              step="1"
              @keyup="item.pan = clamp(item.pan.toFixed(2), -170, 170)"
            >
          </td>
          <td>
            <input
              v-model.number="item.tilt"
              type="number"
              class="form-control form-control-sm"
              min="-80"
              max="80"
              step="1"
              @keyup="item.tilt = clamp(item.tilt.toFixed(2), -80, 80)"
            >
          </td>
          <td>
            <button
              class="btn btn-sm btn-secondary btn-block"
              @click="removePosition(index)"
            >
              {{ $t('remove') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <hr v-else>
    <span
      v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].ReleaseArea')"
      class="badge badge-danger"
    >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].ReleaseArea') }}</span>
    <div class="text-right mt-2">
      <button
        class="btn btn-sm btn-primary"
        @click="addPosition()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="plus"
        />
        <span>{{ $t('add') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlowEditorReleaseArea',
  props: {
    flowArea: {
      type: Object,
      required: true
    },
  },
  methods: {
    clamp (num, min, max) {
      return num <= min ? min : num >= max ? max : num;
    },
    round (number) {
      //Round values to 2 decimal digits
      return Math.round(number * 1e2) / 1e2;
    },
    removePosition (index) {
      this.flowArea.releaseArea.splice(index, 1);
      this.update();
    },
    addDefaultReleaseArea () {
      this.flowArea.releaseArea.push({ pan: -10, tilt: 10 });
      this.flowArea.releaseArea.push({ pan: 10, tilt: 10 });
      this.flowArea.releaseArea.push({ pan: 10, tilt: -10 });
      this.flowArea.releaseArea.push({ pan: -10, tilt: -10 });
      this.update();
    },
    addPosition () {
       if (this.flowArea.releaseArea.length === 0) {
        this.addDefaultReleaseArea();
        return;
      }
      let pan = 0;
      let tilt = 0;

      if (this.flowArea.releaseArea.length >= 2) {
        let firstPosition = this.flowArea.releaseArea[0];
        let lastPosition = this.flowArea.releaseArea[this.flowArea.releaseArea.length - 1];
        pan = this.round((firstPosition.pan + lastPosition.pan) / 2);
        tilt = this.round((firstPosition.tilt + lastPosition.tilt) / 2);
      }

      this.flowArea.releaseArea.push({ pan: pan, tilt: tilt });
      this.update();
    },
    update () {
      this.$emit('update');
    },
  }
}
</script>